import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { RatingBarModule } from '../rating-bar/rating-bar.module';



@NgModule({
  declarations: [TestimonialsComponent],
  imports: [
    CommonModule,
    MatCardModule,
    RatingBarModule
  ],
  exports:[
    TestimonialsComponent
  ]
})
export class TestimonialsModule { }
