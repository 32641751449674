import { SeasoningReview } from "./testimonials.models";


class Review
{
    id:number;
    Product:string
    Review:string
    User:string
    Stars:number
    RateMax:number
    date:string

    constructor(id:number,Prodcut:string, Review:string, User:string, Stars:number, RateMax:number, date:string )
    {
        this.id = id;
        this.Product = Prodcut;
        this.Review = Review;
        this.User = User;
        this.Stars = Stars;
        this.RateMax = RateMax;
        this.date = date
    }

}

 export class SeasoningsReviews
{
    constructor(){}

    reviews:Array<SeasoningReview> = [new Review(0,
        "Dan-O's Orignal Seasoning",
        `They are great love all the flavors highly recommend these seasonings tastes great on everything`,
    "Heather C",
    5,5, 
    "04/28/22"),
     new Review(1,"Dan-O's Orignal Seasoning",
     `My husband ordered it because he is on a restricted diet, and it did not disappoint.
    Go DanO`,
    "Susan E"
    ,5,5,
    "03/21/22"), new Review(2,"Dan-O's Orignal Seasoning",
    `Put it on literally everything I make now`,
    "Justin S",
    5,5,
     "04/27/22")]

}




