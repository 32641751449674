
import { HttpClient } from '@angular/common/http';
import { Component,OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Observable, of, } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

constructor(
  private http:HttpClient,
 private swUpdate: SwUpdate,
  private router:Router)
  {}




breakPoint$:Observable<boolean>;



ngOnInit(): void {


  if (this.swUpdate.isEnabled) {

    this.swUpdate.versionUpdates.subscribe((event:VersionEvent) => {
      console.log(event)
      if(event.type == 'VERSION_DETECTED'){
     window.location.reload();
      }
    });
  }

  

       
    
    

    

    

  


  this.router.events.subscribe((e : RouterEvent) => {
    if (e instanceof NavigationStart) {

    }
    if (e instanceof NavigationEnd) {
      document.body.scrollTop = 0;
       
    }
    if (e instanceof NavigationCancel) {
    }
    if (e instanceof NavigationError) {
    }
  })
 
}


}
