import { Component, OnDestroy, OnInit } from '@angular/core';
import { SeasoningsReviews } from '../sharedComponents/testimonials/models/seasoning.reviews';
import { SeasoningReview } from '../sharedComponents/testimonials/models/testimonials.models';


@Component({
  selector: 'app-landingPage-layout',
  templateUrl: './app-landingPage-layout.html',
  styleUrls: ['./app-landingPage-layout.scss']
})
export class AppLandingPageLayout implements OnInit, OnDestroy{

  constructor(){}

  seasoningReviews:Array<SeasoningReview>;
  review_to_display:{review:SeasoningReview, displayNxt:boolean, displayPrv:boolean};
  interval:any = null
  reviewNumber:number = 1


  ngOnInit(): void {


    this.seasoningReviews= new SeasoningsReviews().reviews
    this.review_to_display = {review:this.seasoningReviews[0], displayNxt:this.seasoningReviews.length>1?true:false, displayPrv:false} 

    this.interval =  setInterval(()=>{ 
      if(this.reviewNumber > 2){this.reviewNumber = 0}
      this.sendNewReview(this.reviewNumber)
      this.reviewNumber++
    },5000)
  }


  sendNewReview(newReview:number)
  {
    let next:boolean = true;
    let prv:boolean = true;
    if(newReview == 0){prv = false}
    if(this.seasoningReviews.length -1 == newReview ){next = false}
    this.review_to_display = {review:this.seasoningReviews[newReview], displayNxt:next, displayPrv:prv}

  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

}
