import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { InstallPwaService } from './install-pwa.service';
import { AppLandingPageLayout } from './landing-page/app-landingPage-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestimonialsModule } from './sharedComponents/testimonials/testimonials.module';
import { InterceptorService } from './interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';


// const initializer = (pwaService: InstallPwaService) => () => pwaService.initPwaPrompt();


@NgModule({

  declarations: [
    AppComponent,
    AppLandingPageLayout

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    CommonModule,
    TestimonialsModule,
    MatDialogModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],

  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    {provide: LocationStrategy, useClass: PathLocationStrategy}


    // {provide: APP_INITIALIZER, useFactory: initializer, deps: [InstallPwaService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
