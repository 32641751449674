

<div [@openClose]="isOpen ? 'open' : 'closed'" class="w-100 reviewsColHeight">
 <div class="container h-100 d-flex flex-column justify-content-center">
   <div class="row d-flex justify-content-center">
     <div class="col-sm-10 d-flex flex-column justify-content-center">
       <div class="d-flex justify-content-center">
        <app-rating-bar 
        [level]="'5'" 
        [rateMax]="'5'"
        [FilledIconToUse]="'fas fa-star'"
        [emptyIconToUse]="'far fa-star'"
        [additionalClassFilled]="'appYellow starsSize'"
        [additionalClassEmpty]="'starsSize'">
        </app-rating-bar>
       </div>

      <p class="pt-2 text-white text-center bold900 textStyle mb-0">
        {{reviewToDisplay?.review.Review}}
       </p>
       <p class="text-white text-center">{{reviewToDisplay?.review.User}}</p>
     </div>

   </div>
 </div>
 </div>











