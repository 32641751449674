import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class RatingBarComponent implements  OnChanges {

  constructor() { }

  displayedIcons:Array<{class:string}> = []

  @Input() FilledIconToUse:string
  @Input() emptyIconToUse:string
  @Input() additionalClassFilled:string
  @Input() additionalClassEmpty:string
  @Input() style:string
  @Input()rateMax:number
  @Input()level:number



 ngOnChanges(changes: SimpleChanges): void {
   if(changes["level"])
   {

    this.displayedIcons = []
    this.getDisplayedIcons()
   }
 }

 getDisplayedIcons()
 {
    
   
   for (let i=0; i < this.rateMax; i++) {
     if(i < +this.level )
     {
      this.displayedIcons.push({class:this.FilledIconToUse + " " + this.additionalClassFilled});
     }
     else
     {
      this.displayedIcons.push({class:this.emptyIconToUse+ " " + this.additionalClassEmpty});
     }
  
}

 }

}
