import {  ChangeDetectionStrategy, ChangeDetectorRef, Component,  Input, OnChanges, SimpleChanges } from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { SeasoningReview } from '../models/testimonials.models';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        width: '100%',
        
        opacity: 1,
        backgroundColor: "transperant"
      })),
      state('closed', style({
        width: '0px',
        
        opacity: 0.5,
        backgroundColor: 'black'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnChanges  {

  constructor(private change:ChangeDetectorRef) { }

  isOpen:boolean = true

  @Input() reviewToDisplay:{review:SeasoningReview, displayNxt:boolean, displayPrv:boolean} 

  toggle() {
    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.isOpen = !this.isOpen 
    }, 400);
    
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.toggle()
  }


  reviewChanged(changeType:string)
  {

    this.toggle()

  }

}
