import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppLandingPageLayout } from './landing-page/app-landingPage-layout';
import { RouteNotFoundComponent } from './sharedComponents/route-not-found/route-not-found.component';

const routes: Routes = [

  {path:"", component:AppLandingPageLayout},
  {path:"selection", loadChildren:()=> import(`./mainApp/main-app.module`).then(m=>m.MainAppModule) },
  {path:"**", component:RouteNotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration:"enabled", preloadingStrategy:PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
