import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingBarComponent } from './rating-bar/rating-bar.component';



@NgModule({
  declarations: [RatingBarComponent],
  imports: [
    CommonModule
  ],
  exports:[
    RatingBarComponent
  ]
})
export class RatingBarModule { }
