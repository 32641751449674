import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LoadingSpinnerCssComponent } from './sharedComponents/loading-spinner-css/loading-spinner-css.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';




@Injectable({providedIn:"root"})

export class InterceptorService implements HttpInterceptor {

  constructor(private dialog: MatDialog ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loading:MatDialogRef<LoadingSpinnerCssComponent>;
    console.log(req)

     try{
      let hideSpinner:boolean =req.headers.has("hideSpinner")
    if(!hideSpinner)
    {
      loading = this.openSpinner();
    }

     }
    catch(error)
    {

    }
    let newRequest = null;
    if(req.method == "POST"){
      const newBody = JSON.stringify({...req.body})

      newRequest = req.clone({body:`${newBody}`, withCredentials:true,headers: req.headers.set('Content-Type', 'application/json') });
    }
    else
    {
      newRequest = req.clone({withCredentials:true,headers: req.headers.set('Content-Type', 'application/json') });
    }
      return next.handle(newRequest).pipe(map((event:HttpEvent<any>)=>{
        if (event instanceof HttpResponse) {
          loading?.close()
        }
        return event
      }), catchError((error)=>{
        loading?.close()
        return throwError(error)
      }))
  }

  private openSpinner():MatDialogRef<LoadingSpinnerCssComponent>
  {
    return this.dialog.open(
      LoadingSpinnerCssComponent,{scrollStrategy: new NoopScrollStrategy(), disableClose: true, panelClass: 'loadingSpinnerBackground'}
      )
  }
}
